import React, {useEffect, useState} from 'react';
import AOS from "aos";

export default function Image() {
    const [imgas] = useState([
        {
            img: '/img/1.png',
            name: 'img1',
        },  {
            img: '/img/2.png',
            name: 'img2'
        },  {
            img: '/img/3.png',
            name: 'img3'
        },  {
            img: '/img/4.png',
            name: 'img4'
        },  {
            img: '/img/5.png',
            name: 'img5'
        },  {
            img: '/img/6.png',
            name: 'img6'
        },  {
            img: '/img/7.png',
            name: 'img7'
        },  {
            img: '/img/8.png',
            name: 'img8'
        },  {
            img: '/img/9.png',
            name: 'img9'
        },  {
            img: '/img/10.png',
            name: 'img10'
        },  {
            img: '/img/11.png',
            name: 'img11'
        },  {
            img: '/img/12.png',
            name: 'img12'
        },

    ])
    useEffect(() => {
        AOS.init()
    })
    return (
        <div className={"row "}>
            {imgas.map((item, index) => (
            <div data-aos="fade-right" data-aos-duration="1500" className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <img className={"w-100"} src={item.img} alt={item.name}/>
                    </div>
                </div>

            </div>
            ))}
        </div>






    )
}