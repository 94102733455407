import React, {useState} from 'react';
import {useEffect, useRef} from "react";
// import Chart from "./Chart";
import Carousel from "./Carousel";
import AOS from "aos";
import 'aos/dist/aos.css';
import Slider from "react-slick";
import "./Home.css"
import Image from "./Image";
import {FaMapMarkedAlt, FaTractor, FaSatellite, FaSimCard} from 'react-icons/all';
import {
    BsSpeedometer,
    BsFillArrowDownSquareFill,
    BsFillMenuButtonWideFill,
    BsFillLightningChargeFill,
    BsBatteryCharging
} from 'react-icons/bs';
import {AiFillSetting, AiFillControl} from 'react-icons/ai';
import {TiDocumentText} from 'react-icons/ti';
import {IoIosSpeedometer, IoMdBatteryCharging} from 'react-icons/io';
import {MdMapsHomeWork, MdSecurity} from 'react-icons/md';
import {BiMemoryCard} from 'react-icons/bi';
import {AiTwotoneThunderbolt} from 'react-icons/ai';
import {GiStarsStack, GiGearHammer} from 'react-icons/gi';
import {FcDataProtection} from 'react-icons/fc';
import {VscSymbolInterface} from 'react-icons/vsc';
import gps1 from '../imges/gps1.png';
import gps2 from '../imges/gps2.png';
import gps3 from '../imges/gps3.png';
import gps4 from '../imges/gps4.png';
import gps5 from '../imges/gps5.png';

import img1 from '../imges/carusel1.png';
import img2 from '../imges/carusel2.png';
import img3 from '../imges/carusel3.png';
import down1 from '../imges/FMB920.pdf';
import down2 from '../imges/FMB125.pdf';
import down3 from '../imges/low_energy.jpg';
import down4 from '../imges/LLS5.pdf';
import down5 from '../imges/TD-150.pdf';
import {useTranslation} from "react-i18next";
import car from "../video/video-gps.mp4";

import car_img from "../video/vid-gps-img.png"
import VideoPlayer from "react-video-js-player";

function Home() {


    const {t} = useTranslation()

    const videoSrc = car;
    const imgV = car_img


    useEffect(() => {
        AOS.init()
    })
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        cssEase: "linear"
    };

    return (
        <div className={"container-fluid color"}>
            <div className={"row section-row "}>
                <div className="col-lg-12">
                    <Slider {...settings}>

                        <div>
                            <img className={"w-100"} src={img1} alt="img-gi1"/>
                        </div>
                        <div>
                            <img className={"w-100"} src={img2} alt="img-gi2"/>
                        </div>
                        <div>
                            <img className={"w-100"} src={img3} alt="img-gi3"/>
                        </div>

                    </Slider>
                </div>
            </div>



            <div className="row row-1 pt-4">
                <div className="col-xl-6 col-lg-6 col-md-10 col-sm-12" data-aos="fade-right"
                     data-aos-duration="1500">

                    <VideoPlayer
                        src={videoSrc}
                        panel={imgV}
                    />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-10 col-sm-12" data-aos="fade-left"
                     data-aos-duration="1500">
                    <div className={"tansport-text"}>
                        {t('video-p')}
                    </div>

                </div>
            </div>
            <div className="row">
                <div className={"h2 text-center"}>{t('gps-monitor')}</div>


                <div data-aos="fade-right" data-aos-duration="1500" className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <div className={"display-4 text-success text-center"}><FaMapMarkedAlt/></div>
                            <h5 className={"text-success text-center"}>{t('gps-monitor-1')}</h5>
                        </div>
                        <div className="card-body li-1">
                            <li>{t('gps-monitor-li1-1')}</li>
                            <li>{t('gps-monitor-li1-2')}</li>
                            <li>{t('gps-monitor-li1-3')}</li>
                        </div>
                    </div>
                </div>
                <div data-aos="fade-left" data-aos-duration="1500" className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <div className={"display-4 text-success text-center"}><BsSpeedometer/></div>
                            <h5 className={"text-success text-center"}>{t('gps-monitor-2')}</h5>
                        </div>
                        <div className="card-body li-1">
                            <li>{t('gps-monitor-li2-1')}</li>
                            <li>{t('gps-monitor-li2-2')}</li>
                            <li>{t('gps-monitor-li2-3')}</li><br/>
                        </div>
                    </div>
                </div>
                <div data-aos="fade-right" data-aos-duration="1500" className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <div className={"display-4 text-success text-center"}><AiFillControl/></div>
                            <h5 className={"text-success text-center"}>{t('gps-monitor-3')}</h5>
                        </div>
                        <div className="card-body li-1">
                            <li>{t('gps-monitor-li3-1')}</li>
                            <li>{t('gps-monitor-li3-2')}</li>
                            <li>{t('gps-monitor-li3-3')}</li><br/>
                        </div>
                    </div>
                </div>
                <div data-aos="fade-left" data-aos-duration="1500" className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <div className={"display-4 text-success text-center"}><MdMapsHomeWork/></div>
                            <h5 className={"text-success text-center"}>{t('gps-monitor-4')}</h5>
                        </div>
                        <div className="card-body li-1">
                            <li>{t('gps-monitor-li4-1')}</li>
                            <li>{t('gps-monitor-li4-2')}</li>
                            <li>{t('gps-monitor-li4-3')}</li><br/><br/>
                        </div>
                    </div>
                </div>
                <div data-aos="fade-right" data-aos-duration="1500" className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <div className={"display-4 text-success text-center"}><IoIosSpeedometer/></div>
                            <h5 className={"text-success text-center"}>{t('gps-monitor-5')}</h5><br/><br/><br/>
                        </div>
                        <div className="card-body li-1">
                            <li>{t('gps-monitor-li5-1')}</li>
                            <br/>
                            <li>{t('gps-monitor-li5-2')}</li><br/><br/><br/><br/>

                        </div>
                    </div>
                </div>
                <div data-aos="fade-left" data-aos-duration="1500" className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <div className={"display-4 text-success text-center"}><FaTractor/></div>
                            <h5 className={"text-success text-center"}>{t('gps-monitor-6')}</h5><br/><br/>
                        </div>
                        <div className="card-body li-1">
                            <li>{t('gps-monitor-li6-1')}</li>
                            <li>{t('gps-monitor-li6-2')}</li>
                            <li>{t('gps-monitor-li6-3')}</li><br/><br/><br/><br/>
                        </div>
                    </div>
                </div>
                <div data-aos="fade-right" data-aos-duration="1500" className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <div className={"display-4 text-success text-center"}><AiFillSetting/></div>
                            <h5 className={"text-success text-center"}>{t('gps-monitor-7')}</h5><br/><br/>
                        </div>
                        <div className="card-body li-1">
                            <li>{t('gps-monitor-li7-1')}</li>
                            <li>{t('gps-monitor-li7-2')}</li>
                        </div>
                    </div>
                </div>
                <div data-aos="fade-left" data-aos-duration="1500" className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <div className={"display-4 text-success text-center"}><TiDocumentText/></div>
                            <h5 className={"text-success text-center"}>{t('gps-monitor-8')}</h5>
                        </div>
                        <div className="card-body li-1">
                            <li>{t('gps-monitor-li8-1')}</li>
                            <li>{t('gps-monitor-li8-2')}</li>
                            <li>{t('gps-monitor-li8-3')}</li><br/>
                        </div>
                    </div>
                </div>


            </div>
            <div className="row mt-4 row-1">
                <div className="h2 text-center">{t('uskunalar-1')}</div>

                <div data-aos="fade-right" data-aos-duration="1500" className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <h3 className={'text-center text-success'}>{t('tansport-m1')}</h3>
                    <div className={"ml-5 h4"}>{t('gps-t1')}</div>
                    <p className={"ml-5"}>{t('gps-t-text1')}</p>
                    <ul className={"d-flex list-unstyled"}>
                        <li className={"border-icon"}><FaSatellite/><p className={"li-p"}>{t('gps-i1-1')}</p></li>
                        <li className={"border-icon"}><BiMemoryCard/><p className={"li-p"}>{t('gps-i1-2')}</p></li>
                        <li className={"border-icon"}><AiTwotoneThunderbolt/><p className={"li-p"}>{t('gps-i1-3')}</p>
                        </li>

                    </ul>
                    <ul className={"d-flex list-unstyled ml-5"}>

                        <li className={"border-icon"}><IoMdBatteryCharging/><p className={"li-p"}>{t('gps-i1-4')}</p>
                        </li>
                        <li className={"border-icon"}><GiStarsStack/><p className={"li-p"}>{t('gps-i1-5')}</p></li>

                    </ul>


                </div>
                <div data-aos="fade-left" data-aos-duration="1500" className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <a href={down1} download="FMB120">
                        <img className={"w-100"} src={gps1} alt="gps"/>
                        <button type="down" className="btn btn-info"><BsFillArrowDownSquareFill/> {t('uskuna-btn')}
                        </button>
                    </a>

                </div>
            </div>
            <div className="row mt-4 ">
                <div data-aos="fade-right" data-aos-duration="1500" className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <a href={down2} download="FMB920">
                        <img className={"w-100"} src={gps2} alt="gps"/>
                        <button type="button" className="btn btn-info"><BsFillArrowDownSquareFill/> {t('uskuna-btn')}
                        </button>
                    </a>
                </div>
                <div data-aos="fade-left" data-aos-duration="1500" className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <h3 className={'text-center text-success'}>{t('tansport-m2')}</h3>
                    <p>
                        {t('tansport-m2-text')}
                    </p>
                    <div className={"ml-5 h4"}>{t('gps-t2')}</div>
                    <p className={"ml-5"}>
                        {t('gps-t-text2')}
                    </p>
                    <ul className={"d-flex list-unstyled "}>
                        <li className={"border-icon"}><FaSimCard/><p className={"li-p"}>{t('gps-i2-1')}</p></li>
                        <li className={"border-icon"}><BsFillMenuButtonWideFill/><p
                            className={"li-p"}>{t('gps-i2-2')}</p>
                        </li>
                        <li className={"border-icon"}><BiMemoryCard/><p className={"li-p"}>{t('gps-i2-3')}</p></li>
                    </ul>
                    <ul className={"d-flex list-unstyled"}>
                        <li className={"border-icon"}><AiTwotoneThunderbolt/><p className={"li-p"}>{t('gps-i2-4')}</p>
                        </li>

                        <li className={"border-icon"}><IoMdBatteryCharging/><p className={"li-p"}>{t('gps-i2-5')}</p>
                        </li>

                        <li className={"border-icon"}><GiStarsStack/><p className={"li-p"}>{t('gps-i2-6')}</p></li>
                    </ul>


                </div>

            </div>
            <div className="row mt-4 row-1">

                <div data-aos="fade-right" data-aos-duration="1500" className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <h3 className={'text-center text-success'}>{t('tansport-m3')}</h3>
                    <ul className={"list-unstyled"}>
                        <li className={"d-flex icons-1 "}><MdSecurity className={""}/>
                            <p className={"ul-li-p"}>{t('gps-i3-1')}</p>
                        </li>
                        <li className={"d-flex icons-1 "}><IoMdBatteryCharging className={""}/>
                            <p className={"ul-li-p"}>
                                {t('gps-i3-2')}
                            </p>
                        </li>
                        <li className={"d-flex icons-1 "}><GiGearHammer className={"display-3"}/>
                            <p className={"ul-li-p mt-2 ml-2"}>
                                {t('gps-i3-3')}
                            </p>
                        </li>

                    </ul>

                </div>
                <div data-aos="fade-left" data-aos-duration="1500" className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <a href={down3} download="low-energy">
                        <img className={"w-100"} src={gps3} alt="gps2"/><br/>
                        <button type="button" className="btn btn-info"><BsFillArrowDownSquareFill/> {t('uskuna-btn')}
                        </button>
                    </a>
                </div>
            </div>
            <div className="row">
                <div data-aos="fade-right" data-aos-duration="1500"
                     className="col-xl-4 col-lg-4 col-md-12 col-sm-12 d-flex">
                    <a href={down4} download="low-energy">
                        <img className={"w-100"} src={gps4} alt="gps"/><br/>
                        <button type="button" className="btn btn-info"><BsFillArrowDownSquareFill/> {t('uskuna-btn')}
                        </button>
                    </a>
                    <ul>
                        <li className={"ul-li"}><FcDataProtection className={"h1 mt-2"}/><p className={"li-p"}>IP
                            69K</p></li>
                        <li className={"ul-li"}><VscSymbolInterface className={"h1 mt-2"}/><p
                            className={"li-p"}>RS-232 <br/>RS-485</p></li>
                        <li className={"ul-li"}><BsFillLightningChargeFill className={"h1 mt-2"}/><p
                            className={"li-p"}>7-75B</p></li>
                        <li className={"ul-li"}><BsBatteryCharging className={"h1 mt-2"}/><p className={"li-p"}>40
                            MA</p></li>
                    </ul>
                </div>
                <div data-aos="fade-bottom" data-aos-duration="1500" className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                    <div className={"tansport-text"}>
                        <div className="card card-header">{t('tansport-text-4')}</div>
                    </div>
                </div>
                <div data-aos="fade-left" data-aos-duration="1500"
                     className="col-xl-4 col-lg-4 col-md-12 col-sm-12 d-flex">
                    <ul>
                        <li className={"ul-li"}><FcDataProtection className={"h1 mt-2"}/><p className={"li-p"}>IP 67</p>
                        </li>
                        <li className={"ul-li"}><VscSymbolInterface className={"h1 mt-2"}/><p
                            className={"li-p"}>RS-485 <br/>A,F</p></li>
                        <li className={"ul-li"}><BsFillLightningChargeFill className={"h1 mt-2"}/><p
                            className={"li-p"}>9-50B</p></li>
                        <li className={"ul-li"}><BsBatteryCharging className={"h1 mt-2"}/><p className={"li-p"}>30
                            MA</p></li>
                    </ul>
                    <a href={down5} download="low-energy ">
                        <img className={"home-img-width "} src={gps5} alt="gps"/><br/>
                        <button type="button" className="btn btn-info"><BsFillArrowDownSquareFill/> {t('uskuna-btn')}
                        </button>
                    </a>

                </div>
            </div>
            <div className="row">
                <Carousel/>
            </div>

            <Image/>

        </div>

    )
}

export default Home