import React, {useState} from "react";
import './News.css';
import news1 from "../imges/news1.png";
import news2 from "../imges/news2.png";
import news3 from "../imges/news3.png";
import news4 from "../imges/news4.png";
import news5 from "../imges/news5.png"
import {useTranslation} from "react-i18next";

export default function News() {

    const {t} = useTranslation()

    return (
        <div className={"container-fluid section-row"}>
            <h1 className={"text-center"}>{t ('news')}</h1>
            <div className="row news-col-6 p-4">
                <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12">
                    <img className={"w-100 "} src={news5} alt="news5"/>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12 mt-3">
                    <h4>
                        Ekspert fesh pratuk emjej Fermer xo'jaligi
                    </h4>
                    <p className={"mt-4"}>
                        {t('news1')}
                    </p> <hr/>
                    <i className="bi bi-calendar-day"> 2022.06.15</i>


                </div>
            </div>
            <br/>
            <div className="row news-col-6 p-4">
                <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12">

                    <img className={'w-100'} src={news4} alt="news4"/>


                </div>
                <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12 mt-3 ">
                    <h4>
                        Yangi dormon 2020 Fermer xo'jaligi
                    </h4>
                    <p className={"mt-4"}>
                        {t('news2')}
                    </p> <hr/>
                    <i className="bi bi-calendar-day"> 2022.06.15</i>
                </div>
            </div>
            <br/>
            <div className="row news-col-6 p-4">
                <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12">

                    <img className={"w-100"} src={news3} alt="news3"/>


                </div>
                <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12 mt-3">
                    <h4>
                        Safarobod bog'i fermer xo'jaligi
                    </h4>
                    <p className={"mt-4"}>
                        {t('news3')}
                    </p> <hr/>
                    <i className="bi bi-calendar-day"> 2022.06.10</i>
                </div>
            </div>
            <br/>
            <div className="row news-col-6 p-4">
                <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12">

                    <img className={"w-100"} src={news2} alt="news2"/>


                </div>
                <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12 mt-3">
                    <h4>
                        Holyorov Jahongir kelajagi fermer xo'jaligi
                    </h4>

                    <p className={"mt-4"}>
                        {t('news4')}
                    </p> <hr/>
                    <i className="bi bi-calendar-day"> 2022.06.05</i>
                </div>
            </div>
            <br/>
            <div className="row news-col-6 p-4">
                <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12">
                    <img className={"w-100"} src={news1} alt="news1"/>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12 mt-3">
                    <h4>
                        QIZILTEPA Agroservis MTP
                    </h4>
                    <p className={"mt-4"}>
                        {t('news5')}
                    </p> <hr/>
                    <i className="bi bi-calendar-day"> 2022.06.05</i>
                </div>
            </div>
            <br/>

        </div>
    )
}