import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import 'react-toastify/dist/ReactToastify.css';
import 'flag-icon-css/css/flag-icons.min.css';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from "i18next";
import {useTranslation, initReactI18next} from 'react-i18next'
import HttpApi from 'i18next-http-backend';

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        supportedLngs: ['uz', 'ru'],
        fallbackLng: "uz",
        detection: {
            order: ['cookie','htmlTag', 'localStorage', 'subdomain', 'path'],
            caches: ['cookie ']
        },
        backend: {
            loadPath: '/assets/locales/{{lng}}/translation.json',
        },
        react: {useSuspense: false}

    });


ReactDOM.render(
    <React.StrictMode>

        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);
