import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useTranslation} from "react-i18next";

export default function Carousel(){
const {t} = useTranslation()
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 1100,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    return(

            <div className="row-carusel">
                <h1 className={'text-center'}>{t('bizning-h')}</h1>
                <Slider {...settings} className={"mt-5"}>
                    <div>
                        <img className={"slider-img"} src="https://uzal.uz/wp-content/uploads/2020/11/logo_uzagro.png"
                             alt="Uz-img"/>
                    </div>
                    <div>
                        <img className={"slider-img"} src="https://www.bms.uz/images/logo.png" alt="bms-img"/>
                    </div>
                    <div>
                        <img className={"slider-img"} src="https://imgroup.uz/themes/imgroup-4/img/toplogo.png"
                             alt="gps1-img"/>
                    </div>

                    <div>
                        <img className={"slider-img"} src="https://agrobank.uz/images/logo.svg"
                             alt="gps4-img"/>
                    </div>
                </Slider>
            </div>

    )
}