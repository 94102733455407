
import CountUp from 'react-countup';
import {useEffect} from "react";
import AOS from "aos";
import 'aos/dist/aos.css';
import './About.css';
import chizma from '../imges/chizma.png'
import {useTranslation} from "react-i18next";
function About() {

    const {t} = useTranslation()
    useEffect(() => {
        AOS.init()
    })
    return (
        <div className={"container-fluid"}>

            <div className={"row section-row"}>
                <div className="about-bg">

                </div>
            </div>
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-mg-12 col-sm-12">
                    <table
                        className={"table table-responsive-sm table-bordered text-center"}>
                        <tr>
                            <th colSpan={2} className={"h2"}>
                                "LIZING AXBOROT TEXNOLOGIYALARI" <br/>{t('biz-haqimizda')}
                            </th>

                        </tr>
                        <tr>
                            <th>{t('t-faoliyati')}</th>
                            <td className={"text-success"}>{t('hozirda-m')}</td>
                        </tr>
                        <tr>
                            <th>{t('royxat-o-s')}</th>
                            <td>12.08.2013</td>
                        </tr>
                        <tr>
                            <th>{t('royxat-o-o')}</th>
                            <td>{t('shahar')}</td>
                        </tr>
                        <tr>
                            <th>STIR</th>
                            <td>302681244</td>
                        </tr>
                        <tr>
                            <th>IFUT</th>
                            <td>{t('if-name')}</td>
                        </tr>
                        <tr>
                            <th>THSHT</th>
                            <td>{t('th-name')}</td>
                        </tr>
                        <tr>
                            <th>DBIBT</th>
                            <td>{t('db-name')}</td>
                        </tr>
                        <tr>
                            <th colSpan={2} className={"h3"}>{t('kontact-m')}</th>
                        </tr>
                        <tr>
                            <th>{t ('Manzili')}</th>
                            <td>{t ('m-name')}</td>
                        </tr>
                        <tr>
                            <th>{t('telefon-r')}</th>
                            <td>+998 (71) 207-30-56</td>

                        </tr>

                    </table>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <img className={"w-100"} src={chizma} alt="img-chizma"/>
                </div>
            </div>

        </div>

    )
}

export default About