import {Link} from "react-router-dom";
import AOS from 'aos'
import 'aos/dist/aos.css'
import React, {useEffect} from "react";
import {FaFacebookSquare, FaInstagram, FaTelegram, FaYoutube} from "react-icons/fa";
import uzagrolizing from '../imges/uzagrolizing-img.png'
import logo from '../imges/logo1.svg'
import './Footer.css';
import {useTranslation} from "react-i18next";

function Footer() {

    const {t} = useTranslation()
    useEffect(() => {
        AOS.init()
    })
    return (
        <div className={""}>
            <div className="row footer">
                <div className="col-sm col-lg" data-aos="fade-right" data-aos-duration="500">
                    <h3 className={"text-white"}>{t('biz-b-bog')}</h3>
                    <ul className={"list-unstyled mt-5"}>
                        <li className={"mt-3"}>
                            <a className={"text-white text-decoration-none"} href="tel:+998712073056"> +998 (71) 207-30-56</a>
                        </li>
                        <li className={"mt-3"}>
                            <a className={"text-white text-decoration-none"} href="mailto:jjahongiregamberdiev@gmail.com?subject=lizing"> lat.uzal.uz@gmail.com</a>
                        </li>
                        <li className={"mt-3 text-white"}>{t('joylashgan')}</li>
                    </ul>
                </div>

                <div className="col-sm col-lg" data-aos="fade-right" data-aos-duration="1500">
                    <h3 className={"text-white"}>{t('link')}</h3>
                    <ul className={"list-unstyled mt-5"}>
                        <li className={"mt-3"}><Link to={"/News"} className={"text-decoration-none text-white"}>{t('news')} </Link></li>
                        <li className={"mt-3"}><Link to={"/About"} className={"text-decoration-none text-white"}>{t('about')}</Link></li>
                        <li className={"mt-3"}><Link to={"/Contact"} className={"text-decoration-none text-white"}>{t('contact')}</Link></li>


                    </ul>
                </div>
                <div className="col-sm col-lg" data-aos="fade-right" data-aos-duration="2000">
                    <h3 className={"text-white"}>{t('markaz')}</h3>
                    <Link to={"/"}>
                        <img className={"logo"} src={logo} alt="logo-svg"/>
                    </Link>

                    <img className={"w-75 mt-3 rounded"} src={uzagrolizing} alt="uzagrolizing-img"/>
                    <ul className={"contact-ul list-unstyled"}>

                        <li>
                            <a className={"ml-2 h3"} href="https://web.telegram.org/"><FaTelegram/></a>
                            <a className={"ml-3 h3"} href="https://www.instagram.com/"><FaInstagram/></a>
                            <a className={"ml-3 h3"} href="https://www.facebook.com/"><FaFacebookSquare/></a>
                            <a className={"ml-3 h3"} href="https://www.youtube.com/"><FaYoutube/></a>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
    )
}

export default Footer
