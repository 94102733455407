import {AvField, AvForm} from "availity-reactstrap-validation";
import React, {useEffect} from "react";
import './Contact.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {YMaps, Map, Placemark, FullscreenControl, GeolocationControl, ZoomControl} from "react-yandex-maps";
import {FiMapPin, FiPhoneCall} from 'react-icons/fi';
import {MdOutlineMailOutline} from "react-icons/md";
import {FaTelegram,FaInstagram,FaFacebookSquare,FaYoutube} from 'react-icons/fa';
import {useTranslation} from "react-i18next";
import emailjs from "emailjs-com"


function Contact() {



    useEffect(() => {
        AOS.init()
    })


    const {t} = useTranslation()
    
    function sentEmail(e) {
    e.preventDefault()

        emailjs.sendForm('service_x82shze', 'template_yxa51ch', e.target, 'WGClhxiV2sjhwEECa')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    e.target.reset()


    }

    return (
        <div className={"container-fluid"}>
            <div className="row section-row contact-row">
                <h1 className={"text-center mt-3"}>{t ('biz-b-bog')}</h1>

                <div className="col-auto col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div data-aos="fade-right" data-aos-duration="3000" className="col-sm col-lg">


                        <AvForm onValidSubmit={sentEmail}>
                             <AvField name={'lat'} label={t('input-1')} type={'textarea'}
                                                       required/>
                            <div className="d-lg-flex d-sm-block">
                                <div className="col-sm col-lg">
                                    <AvField name={'name'} label={t('input-2')} type={'text'} required/>
                                </div>
                                <div className="col-sm col-lg">
                                    <AvField name={'email'} label={t('input-3')} type={'email'} required/>
                                </div>
                            </div>
                            <AvField name={'mavzu'} label={t('input-4')} type={'text'} required/>
                            <button className={"btn btn-success p-4 pl-5 pr-5"} type={'submit'}>
                                {t('input-btn')}
                            </button>

                        </AvForm>
                    </div>
                </div>
                <div className="col-auto col-xl-6 col-lg-6 col-md-auto col-sm-12 text-center">
                    <img className={"w-75 h-100 "}
                         src="https://images.squarespace-cdn.com/content/v1/5f0c35077ec8e4104a2e81bd/14b4ad5c-8e5b-420a-bf3d-70bd0f1cc359/content-marketing-1.gif"
                         alt=""/>
                </div>

            </div>
            <div className="row row-2">
                <div className={"h2 text-white ml-3"}>{t('joy')}</div>
                <div className="col-auto col-xl-6 mt-3 col-lg-6 col-md-12 col-sm-12">
                    <YMaps>
                        <Map
                            defaultState={{
                                center: [41.284377, 69.253497],
                                zoom: 15,

                            }}
                        >
                            <Placemark geometry={[41.284377, 69.253497]}/>
                            <FullscreenControl/>
                            <GeolocationControl options={{float: 'left'}}/>
                            <ZoomControl options={{float: 'right'}}/>
                        </Map>
                    </YMaps>
                </div>
                <div className="col-auto col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <ul className={"contact-ul"}>
                        <h2 className={"text-white"}>{t('markaz')}</h2>
                        <li><FiMapPin/>{t('joylashgan')}</li>
                        <li><MdOutlineMailOutline/>
                            <a href="mailto:jjahongiregamberdiev@gmail.com?subject=lizing"> lat.uzal.uz@gmail.com</a>
                        </li>
                        <li><FiPhoneCall/>  <a href="tel:+998712073056"> +998 (71) 207-30-56</a></li>

                        <li>
                            <a className={"ml-2 h3"} href="https://web.telegram.org/"><FaTelegram/></a>
                            <a className={"ml-4 h3"} href="https://www.instagram.com/"><FaInstagram/></a>
                            <a className={"ml-4 h3"} href="https://www.facebook.com/"><FaFacebookSquare/></a>
                            <a className={"ml-4 h3"} href="https://www.youtube.com/"><FaYoutube/></a>
                        </li>
                    </ul>
                </div>

            </div>


        </div>

    )
}

export default Contact