import React, {useState, useEffect} from "react";
import './Stayle.css'
import {BrowserRouter as Router, Route} from "react-router-dom";
import Home from "./componenets/Home/Home";
import About from "./componenets/About/About";
import Contact from "./componenets/Contact/Contact";
import Footer from "./componenets/Footer/Footer";
import Example from "./componenets/Navbar/navbar";
import News from "./componenets/News/News";
import ClipLoader from "react-spinners/ClipLoader";
import {useTranslation} from "react-i18next";

function App() {
const {t} = useTranslation()

const [loading , setLoading] =  useState(false)
    useEffect(()=>{
        setLoading(true)
        setTimeout(()=>{
        setLoading(false)

        },5000 )

    },[])


    return (
        <div className={"container-fluid enable-responsive-font-size embed-responsive bbb"}>
            {
                loading ?
                    <ClipLoader color={'#209c2d'} loading={loading} size={40} />
                    :
                    <Router>
                        <div>
                            <Route exact path={"/"}>
                                <Home/>
                            </Route>
                            <Route path={"/News"}>
                                <News/>
                            </Route>
                            <Route path="/About">
                                <About/>
                            </Route>
                            <Route path="/Contact">
                                <Contact/>
                            </Route>

                            <Footer/>
                            <Example/>
                        </div>
                    </Router>
            }
        </div>
    )
}

export default App