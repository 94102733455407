import React, {useState} from 'react';
import {Collapse, Nav, Navbar, NavbarBrand, NavbarToggler} from 'reactstrap';
import {Link} from "react-router-dom";
import 'aos/dist/aos.css'
import logo from '../imges/logo1.svg'
import './Navbar.css';

import {useTranslation} from "react-i18next";
import i18next from "i18next";

const Example = (props) => {

    const {t} = useTranslation()
    const lang =[
        {
            code: 'uz',
            name: "O'zbek",
            country_code: 'uz'
        },
        {
            code: 'ru',
            name: 'Русский',
            country_code: 'ru'
        }
    ]

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <div className={"row"}>
            <div className="navs">
                <Navbar light expand="xl" className={""} data-aos="fade-right" data-aos-duration="3000">
                    <NavbarBrand className={"col-3 "} to="/"><Link to={'/'}>

                        <img className={"logo"} src={logo} alt="logo"/>

                    </Link></NavbarBrand>

                    <NavbarToggler onClick={toggle}/>
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="mr-auto" navbar>


                            <Link to="/" className={"text-decoration-none link-hover"}>{t('home')}</Link>
                            <Link to="/News" className={"text-decoration-none link-hover"}>{t('news')}</Link>
                            <Link to="/About" className={"text-decoration-none link-hover"}>{t('about')}</Link>
                            <Link to={"/Contact"} className={"text-decoration-none link-hover"}>{t('contact')}</Link>
                            <a href={'tel: +998712073056'} className={"text-decoration-none link-hover"}>(71) 207-30-56</a>

                            <div className="dropdown link-hover">


                                <button className="btn  "
                                        type="button"
                                        id="dropdownMenuButton"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false">
                                    <i className="bi bi-globe text-white h4"/>
                                </button>
                                <ul className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton">
                                    {lang.map(({code, name, country_code})=>(
                                         <li key={country_code}>
                                             <button className={"dropdown-item"}
                                             onClick={()=>i18next.changeLanguage(code)}>
                                                 <span className={`flag-icon flag-icon-${ country_code}`}/>&nbsp;
                                                 {name}
                                             </button>
                                         </li>
                                        )
                                    )}
                                </ul>
                            </div>
                        </Nav>

                    </Collapse>
                </Navbar>
            </div>
        </div>
    );
}

export default Example;
